import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/products/vrvideoapps/mammut.jpg";
import image2 from "assets/img/products/vrvideoapps/lexus.jpg";
import image3 from "assets/img/products/vrvideoapps/mcdonalds.jpg";
import image4 from "assets/img/products/vrvideoapps/sbs.jpg";
import image5 from "assets/img/products/vrvideoapps/subaru.jpg";
import image6 from "assets/img/products/vrvideoapps/warner.jpg";

import AIG from "assets/img/logos/aig-480x480.png";
import ATT from "assets/img/logos/att-480x480.png";
import SAS from "assets/img/logos/sas-480x480.png";
import Lexus from "assets/img/logos/lexus-480x480.png";
import LibertyMutual from "assets/img/logos/libertymutual-480x480.png";
import Mammut from "assets/img/logos/mammut-480x480.png";
import McDonalds from "assets/img/logos/mcdonalds-480x480.png";
import MTVMobile from "assets/img/logos/mtvmobile-480x480.png";
import ULA from "assets/img/logos/ula-480x480.png";
import SBS from "assets/img/logos/sbs-480x480.png";
import Subaru from "assets/img/logos/subaru-480x480.png";
import WorldVision from "assets/img/logos/worldvision-480x480.png";

class VRVideoSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>VR Video Apps</h2>
              <h4>Our video specialists can build an awesome app for you. Here's a few examples of more than 100 apps we've made:</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Examples",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image5} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image6} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "Why VR Video?",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          VR video is one of most compelling use cases of modern technology:
                          instead of staring a small screen in front of you, you can step inside
                          to another world and see the story unfold everywhere around you.
                        </p>
                        <p>
                          There are good reasons for choosing VR video as your medium of choice:
                          <br /><br />
                          <ul>
                            <li>
                              <b>Novelty:</b> Majority of people still haven't tested VR even once,
                              and the first-time experiences tend to be overwhelmingly positive.
                              This is great for PR and marketing purposes, where you want attention.
                            </li>
                            <li>
                              <b>Impact:</b> Immersive experiences create stronger feelings and 
                              longer lasting memories than traditional videos. Hence, they work 
                              very well for example as training material for you employees.
                            </li>
                            <li>
                              <b>Safety:</b> Some locations are too difficult to reach or dangerous
                              to enter for humans. A 360° camera can be attached to a telescopic rod 
                              or a moving robot to capture footage in hazardous areas, and viewed in 
                              VR as if physically present.
                            </li>
                            <li>
                              <b>Environment:</b> Everyone needs to reduce their carbon footprint. Replacing 
                              your business trips to factories and facilities with virtual visits is an 
                              easy and efficient way, as it also saves a lot of time and travel costs.
                            </li>
                            <li>
                              <b>COVID-19:</b> During a pandemic, travelling and all kinds of physical 
                              contacts have been limited to the minimum. When physical presence is not 
                              possible, VR is the next best thing to reality. VR headset prices have
                              dropped to the level where every employee can have a personal set.
                            </li>
                          </ul>
                        </p>
                        <p>
                          Finwe noticed the potential for VR videos already in 2013 and created
                          the world's fastest 360° video player for smartphones. Since then, the
                          technology has been commercialized by Finwe with our in-house developed
                          Orion360 SDK, which powers hundreds of VR video apps of globally 
                          recognized brands.
                        </p>
                        <p>
                          We have seen first-hand how VR technology has taken leaps forward in just 
                          a few years. If you still haven't tried it or have only tested early 
                          versions years ago, it is time to try out modern VR video. The fuzzy look
                          of early days is a thing of the past: today we have crisp 8K video and 
                          easy, efficient production flow.
                        </p>
                        <p>
                          As world-wide recognized experts of this technology, our team of specialists have
                          personally created over 100 VR video apps. In the examples tab, you'll see some 
                          of them. When you choose Finwe as the creator of your VR video app, you can 
                          be assured that the team knows what they are doing and will build an awesome
                          experience cost-efficiently and without technical issues.
                        </p>
                        <p>
                          <b>
                            We create VR video apps for mobile, desktop, and VR headsets. Contact us
                            to discuss about your project or idea, and request a quote.
                          </b>
                        </p>
                        <Button
                            round
                            color="success"
                            size="md"
                            href="contact-page/"
                            target="_self"
                            rel="noopener noreferrer"
                          >
                            Contact us
                        </Button>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <h4>Examples of brands to whom we have created VR video apps:</h4>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>

                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={AIG}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={ATT}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={SAS}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={Lexus}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={LibertyMutual}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={Mammut}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={McDonalds}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={MTVMobile}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={ULA}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={SBS}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={Subaru}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={1} className={classes.marginAuto}>
                            <img
                              src={WorldVision}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(VRVideoSection);
